
import { computed, defineComponent, PropType } from 'vue'
import { ManualCommunication } from '../../../../types/GCB2'

export default defineComponent({
    props: {
        item: Object as PropType<ManualCommunication>,
        message : {
            type : String,
            default : ""
        }
    },
    setup(props) {
        const errorText = computed(() => {
            if (props.message) {
                return props.message
            } else {
                const errorCode = props.item?.extra?.lastSendErrorCode
                if (!errorCode) return 'Неизвестная ошибка'
                if (errorCode === 'UNKNOWN_SENDING_ERROR') return 'Неизвестная ошибка'
                if (errorCode === 'DEAD_AUTH') return 'WhatsApp бот не подключен'
                if (errorCode === 'INVALID_NUMBER_FORMAT') return 'Не валидный формат номера телефона'
                if (errorCode === 'WHATSAPP_NOT_FOUND') return 'На телефоне клиента нет WhatsApp'
            }            
        })
        return { errorText }
    },
})
